var iniPopin = function() {
	$("#popin-inner").click(function() { closePopin(); });
	$("#popin-inner .news-close").click(function(e) { e.preventDefault(); closePopin(); });
	$(".popin-inner").click(function(e) {e.stopPropagation();});
};

var closePopin = function() {
	if( $("#popin").length > 0 ){
		$('.popin-inner').stop(true, true).fadeOut('slow', function(){
			$("#popin").remove();
			$("#popin-inner").remove();
			$('html').css('overflow','auto');
		});
	}
};

// Chargement d'un contenu dans une popin
var Popin = function(content){
	$('html').css('overflow','hidden');
	$('<div id="popin-inner" />').appendTo('body').stop(true, true).show(0, function(){
		$('#popin-inner').empty().html(content);
		$('#popin-inner').find('.popin-inner').fadeIn('slow');
		$('#popin-inner').css({'overflow':'auto'});
		iniPopin();
	});
};


