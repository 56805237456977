var iniShadow = function() {
	$("#shadow-inner").click(function() { $.address.value("#"); });
	$("#shadow-inner .news-close").click(function(e) { e.preventDefault(); $.address.value("#"); });
	$(".shadow-inner").click(function(e) {e.stopPropagation();});
};

var closeShadow = function() {
	if( $("#shadow").length > 0 ){
		$('.shadow-inner').stop(true, true).fadeOut('slow', function(){
			$("#shadow").remove();
			$("#shadow-inner").remove();
			$('html').css('overflow','auto');
		});
	}
};

// Chargement d'un contenu dans une shadow
var Shadow = function(retour){
	$('html').css('overflow','hidden');
	// $('<div id="shadow" />').appendTo('body').stop(true, true).show().css("opacity",0.8);
	$('<div id="shadow-inner" />').appendTo('body').stop(true, true).show(0, function(){
		$('#shadow-inner').empty().html(retour);
		$('#shadow-inner').imagesLoaded( function(){
			$('#shadow-inner').find('.shadow-inner').fadeIn('slow');
			$('#shadow-inner').css({'overflow':'auto'});
			iniShadow();
		});
	});
};


