// Main menu toggle
var navigation = responsiveNav("#nav-main", {
	animate: true,        // Boolean: Use CSS3 transitions, true or false
	transition: 400,      // Integer: Speed of the transition, in milliseconds
	label: "Menu",        // String: Label for the navigation toggle
	insert: "after",      // String: Insert the toggle before or after the navigation
	customToggle: "",     // Selector: Specify the ID of a custom toggle
	openPos: "relative",  // String: Position of the opened nav, relative or static
	jsClass: "js",        // String: 'JS enabled' class which is added to <html> el
	init: function(){},   // Function: Init callback
	open: function(){},   // Function: Open callback
	close: function(){}   // Function: Close callback
});

// jQuery stuff
$(document).ready(function() {

	// initiate page scroller plugin //
	$('body').pageScroller({
		navigation: '#nav-main',
		sectionClass: 'inview',
		scrollOffset: -80,
		navigationClass:'current'
	});

 	// Owl Carousel
	$("#owl-example").owlCarousel({
		pagination : false,
		items : 3, //10 items above 1000px browser width
		itemsDesktop: 	false,
		itemsDesktopSmall: [979,2],
		itemsTabletSmall: [750,1],
		afterInit : function(el){
			var start_news = $("#owl-example").find(".start_news").attr('num');
		    el.trigger('owl.jumpTo', parseInt(start_news) ); ///start on 4th position
		}
		// itemsTablet: [768,1]
	});

	var owl = $(".owl-carousel").data('owlCarousel'); 
	// owl.jumpTo( start_news ); // Go to x slide without slide animation

	$("#actions a.prev").click(function(e){
		e.preventDefault();
		owl.prev();
	});

	$("#actions a.next").click(function(e){
		e.preventDefault();
		owl.next();
	});

	// LUDO ------------------ //

	// Check when the viewport goes above 768px wide and slide up the mobile navigation
	function setup_for_width(mql) {
		if (mql.matches) {
			isMobile = false;
		} else {
			isMobile = true;
		}
	}

	loadMap('map-stores', 1);
	loadMap('map-services', 2);
	actionMap();

	var path = [
		[45.770539919957194, 4.873771592974663],
		[45.768624053723265, 4.884457513689995],
		[45.76470230981854, 4.88458625972271],
		[45.76473224783924, 4.880423471331596],
		[45.76380416172714, 4.874072000384331]
	];

	if (( !$.browser.msie ) || (parseInt($.browser.version) > 8)) {
		var width_mql = window.matchMedia("(min-width: 768px)");
		width_mql.addListener(setup_for_width);
		setup_for_width(width_mql);
	}

	var handler = function(data) {
		Shadow(data);
		$.address.title(/>([^<]*)<\/title/.exec(data)[1]);
	};

	if(!$.browser.msie) $.address.wrap(true);
	$.address.crawlable(true).init(function(event) {
		$("a[rel='address']").address();
	}).change(function(event) {
		var page = event.parameters.page ? '/?page=' + event.parameters.page : event.path;
		// alert(page);
		if( page == '/' || page === '' || page == '.' ) {
			if($('#shadow').length > 0) { closeShadow(); }
			$.address.title("Commerces à Villeurbanne | Destination Gratte-Ciel");
		} else {

			$('<div id="shadow" />').appendTo('body').stop(true, true).show().css("opacity",0.8);

			$.ajax({
				url: location.pathname + '?page=' + encodeURIComponent(event.value),
				error: function(XMLHttpRequest, textStatus, errorThrown) {
					// handler(XMLHttpRequest.responseText);
				},
				success: function(data, textStatus, XMLHttpRequest) {
					handler(data);
				}
			});
		}
	});

	// // Villeurbanne
	// $.simpleWeather({
	// 	woeid: "632140",
	// 	unit:'c',
	// 	success: function(weather) {
	// 		html  = '<h6>'+weather.city+'</h6>';
	// 		html += '<div class="weather-info">';
	// 		html += '<img class="weather-icon" src="images/meteo/'+weather.code+'.png">';
	// 		html += '<span class="temp-current">'+weather.temp+'&deg;</span>';
	// 		html += '<span class="temp-max">max '+weather.high+'&deg;</span>';
	// 		html += '<span class="temp-min">min '+weather.low+'&deg;</span>';
	// 		html += '<span class="humidity">'+weather.humidity+'%</span>';
	// 		html += '<span class="wind">'+Math.ceil(weather.wind.speed)+' '+weather.units.speed+'</span>';
	// 		html += '<span class="direction">'+weather.wind.direction.replace("W","O")+'</span>';
	// 		html += '</div>';
	// 		$('#weather-villeurbanne').html(html);
	// 	},
	// 	error: function(error) {
	// 		$('#weather-villeurbanne').html('<p>'+error+'</p>');
	// 	}
	// });
	//
	// // Tokyo
	// $.simpleWeather({
	// 	woeid: "1118370",
	// 	unit:'c',
	// 	success: function(weather) {
	// 		html  = '<h6>'+weather.city+'</h6>';
	// 		html += '<div class="weather-info">';
	// 		html += '<img class="weather-icon" src="images/meteo/'+weather.code+'.png">';
	// 		html += '<span class="temp-current">'+weather.temp+'&deg;</span>';
	// 		html += '<span class="temp-max">max '+weather.high+'&deg;</span>';
	// 		html += '<span class="temp-min">min '+weather.low+'&deg;</span>';
	// 		html += '<span class="humidity">'+weather.humidity+'%</span>';
	// 		html += '<span class="wind">'+Math.ceil(weather.wind.speed)+' '+weather.units.speed+'</span>';
	// 		html += '<span class="direction">'+weather.wind.direction.replace("W","O")+'</span>';
	// 		html += '</div>';
	// 		$('#weather-tokyo').html(html);
	// 	},
	// 	error: function(error) {
	// 		$('#weather-tokyo').html('<p>'+error+'</p>');
	// 	}
	// });
	//
	// // Dubai
	// $.simpleWeather({
	// 	woeid: "1940345",
	// 	unit:'c',
	// 	success: function(weather) {
	// 		html  = '<h6>'+weather.city+'</h6>';
	// 		html += '<div class="weather-info">';
	// 		html += '<img class="weather-icon" src="images/meteo/'+weather.code+'.png">';
	// 		html += '<span class="temp-current">'+weather.temp+'&deg;</span>';
	// 		html += '<span class="temp-max">max '+weather.high+'&deg;</span>';
	// 		html += '<span class="temp-min">min '+weather.low+'&deg;</span>';
	// 		html += '<span class="humidity">'+weather.humidity+'%</span>';
	// 		html += '<span class="wind">'+Math.ceil(weather.wind.speed)+' '+weather.units.speed+'</span>';
	// 		html += '<span class="direction">'+weather.wind.direction.replace("W","O")+'</span>';
	// 		html += '</div>';
	// 		$('#weather-dubai').html(html);
	// 	},
	// 	error: function(error) {
	// 		$('#weather-dubai').html('<p>'+error+'</p>');
	// 	}
	// });
	//
	// // NEW YORK
	// $.simpleWeather({
	// 	woeid: "2459115",
	// 	unit:'c',
	// 	success: function(weather) {
	// 		html  = '<h6>'+weather.city+'</h6>';
	// 		html += '<div class="weather-info">';
	// 		html += '<img class="weather-icon" src="images/meteo/'+weather.code+'.png">';
	// 		html += '<span class="temp-current">'+weather.temp+'&deg;</span>';
	// 		html += '<span class="temp-max">max '+weather.high+'&deg;</span>';
	// 		html += '<span class="temp-min">min '+weather.low+'&deg;</span>';
	// 		html += '<span class="humidity">'+weather.humidity+'%</span>';
	// 		html += '<span class="wind">'+Math.ceil(weather.wind.speed)+' '+weather.units.speed+'</span>';
	// 		html += '<span class="direction">'+weather.wind.direction.replace("W","O")+'</span>';
	// 		html += '</div>';
	// 		$('#weather-new-york').html(html);
	// 	},
	// 	error: function(error) {
	// 		$('#weather-new-york').html('<p>'+error+'</p>');
	// 	}
	// });


	// FIN LUDO -------------- //


	// POPIN
	if($('#popin').length > 0) {
		var content = $('#popin').html();
		Popin(content);
	}

		// CONTACT
	// Validation Engine
	$("#contact-form").validationEngine({scroll : false});
	$("#contact-form").submit(function(){
		if($("#contact-form").validationEngine('validate')){
			var data = $(this).serialize() + '&act=send';
			var div  = $(this).empty().html('<p>envoi en cours...</p>');
			$.ajax({
				type    : "POST",
				data    : data,
				url     : "contact/",
				success : function(retour) {
					div.html(retour);
				}
			});
		}
		return false;
	});

	$('input, textarea').placeholder();

});

