var isMobile = false;
var defaultLat = 45.7681522;
var defaultLng = 4.8795152;
var defaultZoom = 16;
var map = [];

var loadOverlay = function(index, mapId){

	$marker = map[mapId].markers[index];
	$position = $marker.getPosition();
	$lat = $position.lat();
	$lng = $position.lng();

	map[mapId].setCenter($lat, $lng);

	zoom = ( map[mapId].getZoom() > 17 ) ? map[mapId].getZoom() : 17;
	map[mapId].setZoom(zoom);

	contenu = '<div class="map-overlay"><div class="overlay-content"><h6>'+$marker.title+'</h6><p>'+$marker.adresse+'</p>';
	if( $marker.detail > 0 ) contenu += '<p><a href="'+$marker.url+'" rel="address" class="fancybox btn">en savoir plus</a></p>';
	contenu += '</div></div>';

	map[mapId].removeOverlays();
	map[mapId].drawOverlay({
		lat: $lat,
		lng: $lng,
		verticalAlign:'top',
		horizontalAlign:'right',
		horizontalOffset:20,
		layer:'floatPane',
		// content: '<div class="map-overlay"><div class="overlay-content"><a href="#" class="map-overlay-close">close</a><h6>'+$marker.title+'</h6><p>'+$marker.adresse+'</p><p><a href="'+$marker.url+'" rel="address" class="fancybox btn">en savoir plus</a></p></div></div>'
		// content: '<div class="map-overlay"><div class="overlay-content"><h6>'+$marker.title+'</h6><p>'+$marker.adresse+'</p><p><a href="'+$marker.url+'" rel="address" class="fancybox btn">en savoir plus</a></p></div></div>'
		content : contenu
	});

};

var loadMap = function( mapId, cat ){

	map[mapId] = new GMaps({
		div: '#'+mapId,
		zoom: defaultZoom,
		lat: defaultLat,
		lng: defaultLng,
		scrollwheel: false,
		zoomControlOpt: {
            style : 'SMALL',
            position: 'TOP_RIGHT'
        },
        panControl : false,
		options: {
			'styles': [ { "featureType": "landscape", "stylers": [ { "saturation": -100 } ] },{ "featureType": "poi", "stylers": [ { "hue": "#00b2ff" } ] }, { "featureType": "poi.attraction", "stylers": [ { "lightness": 2 }, { "visibility": "off" } ] },{ "featureType": "poi.business", "stylers": [ { "visibility": "off" } ] },{ "featureType": "poi.school", "stylers": [ { "visibility": "off" } ] },{ "featureType": "poi.place_of_worship", "stylers": [ { "visibility": "off" } ] },{ "featureType": "poi.medical", "stylers": [ { "visibility": "off" } ] }, { "featureType": "road.local", "stylers": [ { "saturation": -100 } ] },{ "featureType": "road.arterial", "stylers": [ { "hue": "#00b2ff" } ] },{ "featureType": "transit.station", "stylers": [ { "hue": "#eeff00" }, { "saturation": 49 }, { "lightness": 15 } ] },{ },{ "featureType": "road.highway", "stylers": [ { "hue": "#00ffa2" }, { "saturation": -100 }, { "lightness": -15 } ] },{ "featureType": "water", "stylers": [ { "hue": "#00aaff" }, { "lightness": -31 } ] },{ } ]
		}
	});

	$.ajax({
		dataType: "json",
		type: "POST",
		data : "cat="+cat,
		url: "stores/",
		error: function(jqXHR, textStatus, errorThrown) {
			alert("Erreur");
		},
		success: function(data){

			$.each(data.stores, function(i,store){
				var element = map[mapId].addMarker({
					id: store.id,
					lat: store.lat,
					lng: store.lng,
					title: store.nom,
					adresse: store.adresse,
					category: store.categorie,
					url: store.url,
					detail : store.detail,
					icon: 'images/icons/'+store.icon+'.png',
					click : function(element){
						loadOverlay(i, this.getMap().getDiv().id);
					}
				});

				if( store.detail > 0 ) element.setVisible();
				else element.setVisible(false);

				var index = i;

		        $('#'+mapId).prev('div').find('a.pan-to-marker[num='+store.id+']').attr('data-marker-index', index).attr('data-marker-map', mapId).click(function(e){
					e.preventDefault();

					if( isMobile === false){
						index = $(this).data('marker-index');
						mapId = $(this).data('marker-map');
						loadOverlay(index, mapId);
					}else{
						var div = $(this).next('div').show();
						var lat = div.find(".little-map-lat");
						var lng = div.find(".little-map-lng");
						var url = GMaps.staticMapURL({
							size: [500, 500],
							lat: lat.val(),
							lng: lng.val(),
							zoom: 15,
							markers: [{lat: lat.val(), lng: lng.val()}]
						});
						div.find(".little-map").css("background","url('"+url+"') 50% 50% no-repeat");
						// $(".store-listing").animate({'margin-left':'-'+(scrollWidth*2)+'px'});
						$(this).parents(".store-listing").animate({'margin-left':'-'+(scrollWidth*2)+'px'});
					}
		        });

			});

			map[mapId].drawCircle({
				lat:defaultLat,
				lng:defaultLng,
				radius:450,
				strokeColor:'#FFFD39',
				fillOpacity:'0.1'
			});

			// mairie
			map[mapId].addMarker({
				lat: 45.766043,
				lng: 4.879601,
				category:'office',
				icon: 'images/icons/mairie.png'
			});

			// Parking
			map[mapId].addMarker({
				lat: 45.767199,
				lng: 4.878926,
				category:'office',
				icon: 'images/icons/parking.png'
			});
    	}
	});

};

var actionMap = function(){

    $(".map-container-global").each(function(){

    	$(this).find("ul:eq(0) > li > a").click(function(e){
	    	e.preventDefault();

	    	var element = $(this).next('ul');
	    	element.show();

	    	scrollWidth = (isMobile) ? element.innerWidth() : $(".store-list").width();

	    	$(this).parents('.store-list').scrollTo(0);

			$(this).parents("ul").animate({'margin-left':'-='+scrollWidth+'px'});

	    	if( !isMobile ){
	    		mapId = $(this).parents("div").next('div.map-container').attr('id');
		    	map[mapId].removeOverlays();
		    	map[mapId].setCenter(defaultLat, defaultLng);
				map[mapId].setZoom(defaultZoom);
		    	markers = map[mapId].markers;

		    	for(var i = 0; i < markers.length; i++){
		        	if(markers[i].category != $(this).attr('num') && markers[i].category != 'office' ){
			        	markers[i].setVisible(false);
			        }else{
			        	markers[i].setVisible();
			        }
			    }
			}
	    });
	});

    $(".map-container-global").each(function(){
	    $(this).find("a.back").click(function(e){
	    	e.preventDefault();

	    	if( !isMobile ){
	    		mapId = $(this).parents("div").next('div.map-container').attr('id');
				map[mapId].setCenter(defaultLat, defaultLng);
				map[mapId].setZoom(defaultZoom);
			    map[mapId].removeOverlays();

				markers = map[mapId].markers;
				for(var i = 0; i < markers.length; i++){
		        	if(markers[i].detail > 0 || markers[i].category == 'office'){
			        	markers[i].setVisible();
			        }else{
			        	markers[i].setVisible(false);
			        }
			    }
			}

			scrollWidth = (isMobile) ? $(this).parents('ul').innerWidth() : $(this).parents(".store-list").width();

	    	$(this).parents("ul.store-listing").animate({'margin-left':'+='+scrollWidth+'px'}, function(){

	    		var pos = $(this).offset();
	    		$(this).find("ul li > div").hide();
	    		if( pos.left >= 0 ){
	    			$(this).find("ul").hide();
	    		}

	    	});

	    });
	});

	$( "#search_stores" ).autocomplete({
		source: function( request, response ) {

			$.ajax({
				url: "stores/",//request url
				dataType: "json",
				data: {
					cat: 1,
			        term: request.term //this will enable countires?term=au		        
			    },
				success: function(data) {
					response($.map(data.stores, function(item) {
						return {
							label: item.nom.replace('&#039;','\''),
							value: item.nom.replace('&#039;','\''),
							id:    item.id
						};
					}));
				}
			});
		},
		minLength: 3,
		delay: 500,
		select: function( event, ui ) {

			var store = $(".pan-to-marker[num="+ui.item.id+"]");
			mapId = store.attr("data-marker-map");
			
			var pos = $(this).offset();
    		store.parents(".store-listing").find("ul li > div").hide();
    		if( pos.left >= 0 ){
    			store.parents(".store-listing").find("ul").hide();
    		}

			var element = store.parent('li').parent('ul');
	    	element.show();

			if( isMobile === false){
				map[mapId].removeOverlays();
				markers = map[mapId].markers;
				for(var i = 0; i < markers.length; i++){
		        	if(markers[i].category != element.prev('a').attr('num') && markers[i].category != 'office' ){
			        	markers[i].setVisible(false);
			        }else{
			        	markers[i].setVisible();
			        }
				}
			}

	    	scrollWidth = (isMobile) ? element.innerWidth() : $(".store-list").width();
	    	store.parents('.store-list').scrollTo(0);	    	
			store.parents(".store-listing").animate({'margin-left':'-'+scrollWidth+'px'});

			store.trigger('click');

		}
	});

	$( "#search_services" ).autocomplete({
		source: function( request, response ) {

			$.ajax({
				url: "stores/",//request url
				dataType: "json",
				data: {
					cat: 2,
			        term: request.term //this will enable countires?term=au		        
			    },
				success: function(data) {
					response($.map(data.stores, function(item) {
						return {
							label: item.nom,
							value: item.nom,
							id:    item.id
						};
					}));
				}
			});
		},
		minLength: 2,
		select: function( event, ui ) {

			var store = $(".pan-to-marker[num="+ui.item.id+"]");
			mapId = store.attr("data-marker-map");
			
			var pos = $(this).offset();
    		store.parents(".store-listing").find("ul li > div").hide();
    		if( pos.left >= 0 ){
    			store.parents(".store-listing").find("ul").hide();
    		}

			var element = store.parent('li').parent('ul');
	    	element.show();

			if( isMobile === false){
				map[mapId].removeOverlays();
				markers = map[mapId].markers;
				for(var i = 0; i < markers.length; i++){
		        	if(markers[i].category != element.prev('a').attr('num') && markers[i].category != 'office' ){
			        	markers[i].setVisible(false);
			        }else{
			        	markers[i].setVisible();
			        }
				}
			}

	    	scrollWidth = (isMobile) ? element.innerWidth() : $(".store-list").width();
	    	store.parents('.store-list').scrollTo(0);	    	
			store.parents(".store-listing").animate({'margin-left':'-'+scrollWidth+'px'});

			store.trigger('click');

		}
	});

};